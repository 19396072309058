import React, { Component } from 'react';

class Buy extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            iva: 0,
            sub_total: 0,
            total: 0
        }

        this.handleventa = this.props.handleVenta.bind(this);
        this.setAmounts = this.props.setAmounts.bind(this);

        
    }

    componentDidMount(){
        var values = [0, 0, 0];

        this.props.Car.forEach(element => {
            var price = element[0].discount == 0 ? element[0].price : element[0].price_variation;
            var qty = element[1];
            var total = price * qty;

            values[0] = values[0] + (total * 0.16);
            values[1] = values[1] + (total - (total * 0.16));
            values[2] = values[2] + total;

            
        });

        


        this.setState({
            iva: values[0].toFixed(2),
            sub_total:values[1].toFixed(2),
            total: values[2].toFixed(2)
        });

        this.setAmounts(values);


    }

    render(){
        return(
                <div className="root">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 divisors_a">
                            <h1>Realizar compra</h1>
                            <hr />
                        </div>                        
                    </div>

                    <div className="row">

                        <div className="col-md-8">

                            {
                                this.props.Car.map((key, index) => {
                                    return(
                                        <div className="row mt-1">
                                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                <img className="img-fluid img-venta" src={key[0].image_path} alt="" />
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 divisors_a">
                                                <h5 className="divisors_e">{key[0].title}</h5>
                                                <h6 className="divisors_e">{key[0].subcategory}</h6>
                                                <p className="divisors_e">{key[0].identification}</p>
                                                <p className="divisors_e">{key[1] == 1 ? '1 Pieza' : key[1] + ' Piezas'}</p>
                                                <p className="divisors_e">$ {key[0].discount == 0 ? key[0].price : key[0].price_variation}</p>
                                            </div>

                                            <hr/>
                                            
                                        </div>

                                    );

                                })
                            }

                            
                        </div>

                        <div className="col-md-4 d-flex">
                            <div className="card bg-light mb-3">
                                <div className="card-header">Orden</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h5 className="card-title">SUB-TOTAL</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <h6 className="card-title">$ {this.state.sub_total}</h6>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <h5 className="card-title">IVA</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <h6 className="card-title">$ {this.state.iva}</h6>
                                        </div>

                                        <div className="col-md-6">
                                            <h5 className="card-title">TOTAL</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <h6 className="card-title">$ {this.state.total}</h6>
                                        </div>
                                    </div>
                                    {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-success w-100" onClick={(e) => this.handleventa(null, 3)}>CONTINUAR</button>
                                </div>
                            </div>
                        </div>
                        <hr/>
                    </div>
                </div>
            
        );
    }
}

export default Buy;