import React, { Component } from 'react';

class Details extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            counter: 1,
            stock: 30
        }

        this.resQuantity = this.resQuantity.bind(this);
        this.handleProducts = this.props.Method.bind(this);
        this.handleventa = this.props.handleVenta.bind(this);
        this.buyNow = this.buyNow.bind(this);
    }

    resQuantity(){
        let c =  this.state.counter;
        if(c > 1){
            this.setState({counter: c - 1})
        }
    }

    increaseQuantity(){
        let c =  this.state.counter;
        if(c < this.state.stock){
            this.setState({counter: c + 1})
        }
    }

    buyNow() {

        this.handleProducts(this.props.producto, this.state.counter);
        this.handleventa(null, 2);       
        
    }




    render(){
        return(
            <div className="root">
                    
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                    <span className="glyphicon glyphicon-refresh"></span>
                    </div>
                <div className="col-md-6 col-sm-12">
                        <img className="img-fluid img-venta" src={this.props.producto.image_path} alt="" />
                </div>
                {/* <h3>{this.props.producto.title}</h3> */}
                
                    <div className="col-md-6 col-sm-12">
                    <div className="row">
                        <div className="col-md-12 b1">
                            <hr></hr>
                            <h3>{this.props.producto.title}</h3>
                            <hr></hr>
                        </div>
                        <div className="col-md-6 col-sm-6"><h6>CATEGORIA:</h6></div>
                        <div className="col-md-6 col-sm-6"> <span>{this.props.producto.category}</span></div>
                        <hr></hr>

                        <div className="col-md-6 col-sm-6"><h6>SUBCATEGORIA:</h6></div>
                        <div className="col-md-6 col-sm-6"> <span>{this.props.producto.subcategory}</span></div>
                        <hr></hr>

                        <div className="col-md-6 col-sm-6"><h6>NUMERO DE PARTE:</h6></div>
                        <div className="col-md-6 col-sm-6"> <span>{this.props.producto.identification}</span></div>
                        <hr></hr>

                        <div className="col-md-6 col-sm-6"><h6>PRECIO:</h6></div>
                        <div className="col-md-6 col-sm-6"> <span>$ { this.props.producto.discount == 0 ? this.props.producto.price : this.props.producto.price_variation}</span></div>
                        <hr></hr>

                        <div className="col-md-12 col-sm-12 s1 b1">
                            <hr></hr>
                            <h3>Descripcion</h3>
                            <hr></hr>
                        </div>
                        
                        <div className="col-md-12 col-sm-12"><span>{this.props.producto.description}</span></div>

                        <div className="col-md-12 col-sm-12 divisors_c">
                            <button className="rounded btn btn-outline-success w-100" onClick={(e) => this.buyNow()}>COMPRAR AHORA</button>
                        </div>
                        <div className="col-md-12 col-sm-12 divisors_c">
                            <button className="rounded btn btn-outline-danger w-100" onClick={(e) => this.handleProducts(this.props.producto, this.state.counter)}> AGREGAR AL CARRITO</button>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 divisors_c">
                                    <a href="#" onClick={(e) => {this.resQuantity()}}>
                                        <h1>-</h1>
                                    </a>                            
                                </div>

                                <div className="col-xs-8 col-md-8 col-sm-8 col-lg-8 divisors_c">
                                    {/* <input type="text" className="form-control counter_buy" value={this.state.counter} readOnly/> */}
                                    <h3 className="text-center text-blue">{this.state.counter}</h3>
                                </div>

                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <a href="#" onClick={(e) => {this.increaseQuantity()}}>
                                        <h1>+</h1>
                                    </a>                                
                                </div>
                            </div>
                        </div>
                        
                        <hr></hr>



                    </div>                  

                </div>
                </div>
            </div>
        );
    };
}

export default Details;