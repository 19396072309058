import React, { Component } from 'react';
import API from './Api';




class Shipment extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            estados: [],
            municipios:[],
            envio: 0,
            subtotal: this.props.subtotal,
            iva: this.props.iva,
            total: this.props.total
        }

        this.handleventa = this.props.handleVenta.bind(this);
        this.handleInputChange = this.props.handleInputChange.bind(this);
        this.updateMount = this.updateMount.bind(this);
        this.setMunicipalities = this.setMunicipalities.bind(this);
        this.setTotalSale = this.props.setTotalSale.bind(this);

    }

    componentDidMount(){
        API.get(`states`)
          .then(response => {
            this.setState({'estados': response.data});
          })
          .catch(response => {
              //handle error
            console.log(response)
          });
    }

    async setMunicipalities(event){
        let num_estado = event.target.value;
        var here = this;

        await API.get(`municipalities/`+num_estado)
          .then(response => {
            var datos = response.data;
            here.setState({municipios: datos});
          })
          .catch(response => {
              //handle error
            console.log(response)
          });
        
    }

    updateMount(e, envio){
        this.handleInputChange(e);


        this.setState({
            envio: envio,
            subtotal: ((this.props.total + envio) * 0.84).toFixed(2),
            iva: ((this.props.total + envio) * 0.16).toFixed(2),
            total: (this.props.total + envio).toFixed(2)
        });
    }

    render(){
        return(
                <div className="root">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>DATOS DE ENTREGA</h1>
                        </div>
                    </div>

                    <div className="row divisors_a">
                        <div className="col-md-8 col-sm-12">
                            <div className="row">
                                <div className="col-md-12 divisors_a">
                                    <h4>1.- Metodo de envio</h4>
                                    <hr />
                                </div>

                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input className={this.props.estado.VALID_ID_SHIPMENT_TYPE ? "form-check-input" : "form-check-input is-invalid"} type="radio" name="ID_SHIPMENT_TYPE" value="1" onChange={(e) => this.updateMount(e, 75.00)}/>
                                        <label className="form-check-label" htmlFor="exampleRadios1">
                                            <h6>MXM $75 ENVIO STANDART A DOMICILIO</h6>
                                        </label>
                                        <hr/>
                                    </div>
                                    <div className="form-check">
                                        <input className={this.props.estado.VALID_ID_SHIPMENT_TYPE ? "form-check-input" : "form-check-input is-invalid"}  value="2" type="radio" name="ID_SHIPMENT_TYPE" id="2" onChange={(e) => this.updateMount(e, 110.00)} />
                                        <label className="form-check-label" htmlFor="exampleRadios2">
                                            <h6>MXM $110 ENVIO EXPRESS</h6>
                                        </label>
                                        <hr/>
                                    </div>   
                                    <div className="form-check">
                                        <input className={this.props.estado.VALID_ID_SHIPMENT_TYPE ? "form-check-input" : "form-check-input is-invalid"} value="3" type="radio" name="ID_SHIPMENT_TYPE" id="3" onChange={(e) => this.updateMount(e, 0.00)}/>
                                        <label className="form-check-label" htmlFor="exampleRadios3">
                                            <h6>RECOGER EN TIENDA</h6>
                                        </label>
                                        <hr/>
                                    </div>
                                    <div className="invalid-feedback">
                                        Elige el metodo de envio
                                    </div>                             
                                </div>
                                

                                <div className="col-md-12 divisors_a">
                                    <h4>2.- Direccion de envio</h4>
                                    <hr />
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="colonia">Colonia</label>
                                    <input type="text" onChange={this.handleInputChange} value={this.props.estado.ADDRESS_1} name="ADDRESS_1" className={this.props.estado.VALID_ADDRESS_1 ? "form-control" : "form-control is-invalid"} id="colonia" aria-describedby="colonia" placeholder="Introduce la colonia" />  
                                    <div className="invalid-feedback">
                                        Ingresa la colonia
                                    </div>                      
                                </div>
                                
                                <div className="col-md-12">
                                    <label htmlFor="calle">Calle</label>
                                    <input type="text" onChange={this.handleInputChange} value={this.props.estado.ADDRESS_2} name="ADDRESS_2" className={this.props.estado.VALID_ADDRESS_2 ? "form-control" : "form-control is-invalid"} id="calle" aria-describedby="calle" placeholder="Introduce la calle" />                                    
                                    <div className="invalid-feedback">
                                        Ingresa la calle
                                    </div> 
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="calle">Numero</label>
                                    <input type="text" onChange={this.handleInputChange} value={this.props.estado.NUMBER_EXT} name="NUMBER_EXT" className={this.props.estado.VALID_NUMBER_EXT ? "form-control" : "form-control is-invalid"} id="numero" aria-describedby="numero" placeholder="Introduce el numero" />                                    
                                    <div className="invalid-feedback">
                                        Ingresa el numero
                                    </div> 
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="calle">Referencia</label>
                                    <input type="text" onChange={this.handleInputChange} value={this.props.estado.DESCRIPTION} name="DESCRIPTION" className={this.props.estado.VALID_DESCRIPTION ? "form-control" : "form-control is-invalid"} id="referencia" aria-describedby="referencia" placeholder="Introduce la referencia" />                                    
                                    <div className="invalid-feedback">
                                        Ingresa la referencia
                                    </div> 
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="cp">Codigo postal</label>
                                    <input type="number" onChange={this.handleInputChange} value={this.props.estado.CP} className={this.props.estado.VALID_CP ? "form-control" : "form-control is-invalid"} name="CP" id="cp" aria-describedby="cp" placeholder="Introduce un codigo postal valido" />                                    
                                    <div className="invalid-feedback">
                                        Ingresa un codigo postal valido
                                    </div>           
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="estado">Estado</label>
                                    <select className="form-control" id="estado"  onChange={(e) => this.setMunicipalities(e)}>
                                        <option value="0" defaultValue>Elige un estado</option>
                                        {
                                            this.state.estados.map((i, k) => {
                                                return(
                                                    <option value={i.NUMBER_STATE}>{i.STATE}</option>
                                                );
                                            })
                                        }
                                        
                                        
                                    </select>
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="estado">Municipio</label>
                                    <select className={this.props.estado.VALID_ID_MUNICIPALITY ? "form-control" : "form-control is-invalid"} name="ID_MUNICIPALITY" id="municipio" onChange={this.handleInputChange} value={this.props.estado.ID_MUNICIPALITY}>
                                    <option disabled value="0" defaultValue>Elige el municipio</option>
                                        {
                                            this.state.municipios.map((x, y) => {
                                                return(
                                                    <option value={x.ID_MUNICIPALITY}>{x.MUNICIPALITY}</option>
                                                );

                                            })
                                        }
                                    </select>
                                    <div className="invalid-feedback">
                                        Elije un municipio
                                    </div> 

                                </div>

                                <div className="col-md-12 divisors_a">
                                    <h4>3.- Datos del contacto</h4>
                                    <hr />
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="nombre">Nombre Completo</label>
                                    <input type="text" onChange={this.handleInputChange} value={this.props.estado.FULLNAME} name="FULLNAME" className={this.props.estado.VALID_FULLNAME ? "form-control" : "form-control is-invalid"} id="nombre" aria-describedby="nombre" placeholder="Introduce tu nombre completo" />                                    
                                    <div className="invalid-feedback">
                                        Ingresa tu nombre completo
                                    </div> 
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="email">Correo electronico</label>
                                    <input type="text" onChange={this.handleInputChange} value={this.props.estado.EMAIL} name="EMAIL" className={this.props.estado.VALID_EMAIL ? "form-control" : "form-control is-invalid"} id="email" aria-describedby="email" placeholder="Introduce tu correo electronico" />                                    
                                    <div className="invalid-feedback">
                                        Ingresa un correo valido
                                    </div> 
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="celular">Celular</label>
                                    <input type="number" onChange={this.handleInputChange} value={this.props.estado.TELEPHONE_1} name="TELEPHONE_1" className={this.props.estado.VALID_TELEPHONE_1 ? "form-control" : "form-control is-invalid"} id="celular" aria-describedby="celular" placeholder="Introduce tu celular" />                                    
                                    <div className="invalid-feedback">
                                        Ingresa un celular valido
                                    </div> 
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="telefono">Telefono</label>
                                    <input type="number" onChange={this.handleInputChange} value={this.props.estado.TELEPHONE_2} name="TELEPHONE_2" className={this.props.estado.VALID_TELEPHONE_2 ? "form-control" : "form-control is-invalid"} id="telefono" aria-describedby="telefono" placeholder="Introduce tu telefono" />
                                    <div className="invalid-feedback">
                                        Ingresa un telefono
                                    </div>
                                </div>

                                <div className="col-md-12 divisors_a">
                                    <h4>4.- Pago</h4>
                                    <hr />
                                </div>

                                <div className="col-md-12">
                                    <button className="btn btn-success w-100" onClick={(e) => 
                                    {
                                        this.setTotalSale(this.state.total);
                                        this.handleventa(null, 4);
                                    }}>
                                        Agregar metodo de pago</button>                                   
                                </div>


                                <div className="col-md-12 divisors_d">
                                </div>                             
                             
                            </div>                            
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="card bg-light mb-3">
                                <div className="card-header">RESUMEN DE COMPRA</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h5 className="card-title">SUB-TOTAL</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <h6 className="card-title">$ {this.state.subtotal}</h6>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <h5 className="card-title">IVA</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <h6 className="card-title">$ {this.state.iva}</h6>
                                        </div>

                                        <div className="col-md-6">
                                            <h5 className="card-title">TOTAL</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <h6 className="card-title">$ {this.state.total}</h6>
                                        </div>
                                    </div>
                                    {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

        );
    }
}

export default Shipment;