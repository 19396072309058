import React, { Component } from 'react';
import { PayPalButton } from "react-paypal-button-v2";
import API from './Api';


class Payment extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            metodo : 0,
            total: 10000000
        }


        this.handleventa = this.props.handleVenta.bind(this);
        this.handleInputChange = this.props.handleInputChange.bind(this);
        this.finishSale =  this.finishSale.bind(this);
    }

    componentDidMount(){

        var Data = new FormData();

        Data.append('ID_PRODUCT_AR', this.props.Products); 
        Data.append('QUANTITY_AR', this.props.Quantity); 
        Data.append('ID_SHIPMENT_TYPE', this.props.SHIPMENT_TYPE); 

        API.post(`sales/price`, Data)
          .then(response => {
            var res = response.data;
              if(res.status_code === 200){
                var total =  res.collection[0].V_TOTAL;
                this.setState({total: total});
              }
          })
          .catch(response => {
              //handle error
            console.log(response)
            alert("La venta no se puede realizar en este momento, intente mas tarde");
            window.location.reload();
          });
    }

    finishSale(orderID, id_transaction){

        var Data = new FormData();
  
        Data.append('V_PAYMENT_IDENTIFICATION', this.props.IDENTIFICATION); 
        Data.append('V_ID_TRANSACTION', id_transaction); 
        Data.append('V_ORDER_ID', orderID); 
        Data.append('V_ID_PAYMENT_TYPE', 2); 
        Data.append('EMAIL', this.props.EMAIL);
        Data.append('NAME', this.props.NAME);


        API.post(`sales/finish`, Data)
            .then(response => {
            var res = response.data;
                
                console.log(res);
                if(res.status_code === 201){
                    alert("Pago completado, verifique su correo electronico para la confirmacion del envio");
                    window.location.reload();
                }else{
                    alert("No se pudo completar el pago en este momento, contacte con soporte tecnico");
                }
            })
            .catch(response => {
                //handle error
            console.log(response)
            });

    }



    render(){

        return (

            <div id="hero" className="secc_top">
                <div className="container">
                    <div className="row divisors_d">
                        <div className="col-md-12">
                            <h1>METODO DE PAGO</h1>
                        </div>
                    </div>

                    <PayPalButton
                    amount={this.state.total}
                    currency="MXN"
                    // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                    onSuccess={(details, data) => {        
                        //console.log(data);
                        //console.log(details);  
                        
                        var id_transaction = details.purchase_units[0].payments.captures[0].id
                        // OPTIONAL: Call your server to save the transaction
                        this.finishSale(data.orderID, id_transaction);
                        
                    }}
                    />

                </div>
            </div>

            
          );
    }
}

export default Payment;