import React, { Component } from 'react';
import API from './Api';
import Productos from './Productos';
import Details from './Details';
import Buy from './Buy';
import Shipment from './Shipment';
import Payment from './Payment';

class Body extends React.Component{
    constructor(props) {
      super(props);
  
      this.state = {
        productos: [],
        subtotal: 0,
        iva: 0,
        total: 0,
        total_sale: 0,
        procesoCompra: 0,
        comprar: 0,
        ID_PRODUCT_AR: '',
        QUANTITY_AR: '',
        ID_SHIPMENT_TYPE: '',
        ADDRESS_1: '',
        ADDRESS_2: '',
        NUMBER_EXT: '',
        CP: '',
        DESCRIPTION: '',
        FULLNAME: '',
        TELEPHONE_1: '',
        TELEPHONE_2: '',
        EMAIL: '',
        ID_MUNICIPALITY: 0,
        ID_PAYMENT_TYPE: 0,
        VALID_ID_SHIPMENT_TYPE: true,
        VALID_ADDRESS_1: true,
        VALID_ADDRESS_2: true,
        VALID_NUMBER_EXT: true,
        VALID_CP: true,
        VALID_DESCRIPTION: true,
        VALID_FULLNAME: true,
        VALID_TELEPHONE_1: true,
        VALID_TELEPHONE_2: true,
        VALID_EMAIL: true,
        VALID_ID_MUNICIPALITY: true,
        PAYMENT_IDENTIFICATION: ''
      };

      this.Data = [];
      
      this.getData = this.getData.bind(this);
      this.handleProducts = this.props.Method.bind(this);
      this.comprarProducto = this.comprarProducto.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.setAmounts = this.setAmounts.bind(this);
      this.saveSale = this.saveSale.bind(this);
      this.setTotalSale = this.setTotalSale.bind(this);
  
    }

    componentDidMount() {
        this.getData();
    }

    setAmounts(param){
      this.setState({
        iva: param[0],
        subtotal: param[1],
        total: param[2]
      });
    }

    setTotalSale(amount){
      this.setState({
        total_sale: amount
      });
    }

    comprarProducto(item, proceso){
      var here = this;
        if(proceso === 1){
          this.setState({comprar: item, procesoCompra: proceso});
        }
        else if(proceso === 3){
          var car = this.props.Car;
          var len = car.length;
          var productos = '';
          var cantidades = '';

          car.forEach((k, i) => {
            var con = (i + 1) < len ? ',' : '';
            productos += k[0].id + con;
            cantidades += k[1] + con;
          });

          here.setState({ID_PRODUCT_AR: productos, QUANTITY_AR: cantidades, procesoCompra: proceso});
          
        }else if(proceso === 4){
          
          // VERIFICAR QUE LOS INPUTS NO ESTEN VACIOS
          
          if(this.validateFields()){
            console.log("Guardando venta");
            this.saveSale();
            this.setState({procesoCompra: proceso});
          }
            
          

        }else if(proceso === 5){
          if(this.state.ID_PAYMENT_TYPE > 0){
            //this.saveSale();
          }
          
        }else{
          this.setState({procesoCompra: proceso});
        }
        
    }

    validateFields(){
      let here = this;
      var flat = true;

      if(here.state.ID_SHIPMENT_TYPE === ''){
        here.setState({VALID_ID_SHIPMENT_TYPE: false});
        flat = false;
      }else{
        here.setState({VALID_ID_SHIPMENT_TYPE: true});
      }

      if(here.state.ADDRESS_1 === ''){
        here.setState({VALID_ADDRESS_1: false});
        flat = false;
      }else{
        here.setState({VALID_ADDRESS_1: true});
      }

      if(here.state.ADDRESS_2 === ''){
        here.setState({VALID_ADDRESS_2: false});
        flat = false;
      }else{
        here.setState({VALID_ADDRESS_2: true});
      }

      if(here.state.NUMBER_EXT === ''){
        here.setState({VALID_NUMBER_EXT: false});
        flat = false;
      }else{
        here.setState({VALID_NUMBER_EXT: true});
      }

      if(!/^[0-9]{5}$/.test(here.state.CP)){
        here.setState({VALID_CP: false});
        flat = false;
      }else{
        here.setState({VALID_CP: true});
      }

      if(here.state.DESCRIPTION === ''){
        here.setState({VALID_DESCRIPTION: false});
        flat = false;
      }else{
        here.setState({VALID_DESCRIPTION: true});
      }

      if(here.state.FULLNAME === ''){
        here.setState({VALID_FULLNAME: false});
        flat = false;
      }else{
        here.setState({VALID_FULLNAME: true});
      }

      if(here.state.TELEPHONE_1.length != 10){
        here.setState({VALID_TELEPHONE_1: false});
        flat = false;
      }else{
        here.setState({VALID_TELEPHONE_1: true});
      }

      if(here.state.TELEPHONE_2 === ''){
        here.setState({VALID_TELEPHONE_2: false});
        flat = false;
      }else{
        here.setState({VALID_TELEPHONE_2: true});
      }

      if(!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(here.state.EMAIL)){
        here.setState({VALID_EMAIL: false});
        flat = false;
      }else{
        here.setState({VALID_EMAIL: true});
      }

      if(here.state.ID_MUNICIPALITY === 0){
        here.setState({VALID_ID_MUNICIPALITY: false});
        flat = false;
      }else{
        here.setState({VALID_ID_MUNICIPALITY: true});
      }

     
      return flat;
    }

    saveSale(){
      try{


        this.Data = new FormData();

        this.Data.append('ID_PRODUCT_AR', this.state.ID_PRODUCT_AR); 
        this.Data.append('QUANTITY_AR', this.state.QUANTITY_AR); 
        this.Data.append('ID_SHIPMENT_TYPE', this.state.ID_SHIPMENT_TYPE); 
        this.Data.append('ADDRESS_1', this.state.ADDRESS_1); 
        this.Data.append('ADDRESS_2', this.state.ADDRESS_2); 
        this.Data.append('CP', this.state.CP); 
        this.Data.append('NUMBER_EXT', this.state.NUMBER_EXT);        
        this.Data.append('TELEPHONE_1', this.state.TELEPHONE_1);
        this.Data.append('TELEPHONE_2', this.state.TELEPHONE_2);
        this.Data.append('DESCRIPTION', this.state.DESCRIPTION); 
        this.Data.append('FULLNAME', this.state.FULLNAME);
        this.Data.append('EMAIL', this.state.EMAIL);
        this.Data.append('ID_MUNICIPALITY', this.state.ID_MUNICIPALITY);

        API.post(`sales`, this.Data)
          .then(response => {
            console.log(response);  
            var res = response.data;

              if(res.status_code == 201){
                this.setState({PAYMENT_IDENTIFICATION: res.message[0].RETURN_VALUE})
              }else{
                alert("No se puede realizar la venta en este momento");
              }
          })
          .catch(response => {
              //handle error
            console.log(response);
            alert("No se puede realizar la venta en este momento");
            window.location.reload();
          });

      }catch(e){
        console.log(e);
        alert("La compra no se pudo procesar, intente mas tarde")
      }

    }

    

    handleInputChange(event) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      this.setState({
        [name]: value
      });

    }

    componentDidUpdate(prevProps){
      if (this.props.Shop !== prevProps.Shop) {
        this.setState({procesoCompra: 2});
      }
    }


    getData(){

      var here = this;
      var datos = [];

      API.get(`store`)
          .then(response => {
            response.data.forEach(x => {
            datos.push(
                  {     id:x.ID_PRODUCT,
                        title:x.TITLE,
                        identification: x.IDENTIFICATION,
                        image_path: window.$host+'cotrapsa_api/storage/app/images/'+x.products_images[0].IMAGE,
                        price: parseFloat(x.price_products[0].PRICE_PRODUCT),
                        price_variation: parseFloat(x.price_products[0].PRICE_VARIATION),
                        discount: x.price_products[0].DISCOUNT,
                        quantity: x.stock_products[0].QUANTITY,
                        description:x.SHORT_DESCRIPTION,
                        subcategory:x.subcategory.SUBCATEGORY,
                        id_subcategory: x.subcategory.ID_SUBCATEGORY,
                        category:x.category.CATEGORY,
                        id_category: x.category.ID_CATEGORY,
                        active: x.active}
              );
              
            });
            
            here.setState({ productos: datos });
          })
          .catch(response => {
              //handle error
            console.log(response)
          });



    }

    render(){
      return(
          
          <div className="container paddindCont">

              {
                this.state.procesoCompra > 0 ?
                  <div className="col-md-2">
                    <a href="#" onClick={(e) => {this.setState({procesoCompra: this.state.procesoCompra - 1});} }>
                        <span className="badge rounded-pill bg-light span_cat">REGRESAR <i className="icofont-recycle-alt"></i></span>                        
                    </a>
                  </div> :                
                ""
              }    

            
              {this.state.procesoCompra  === 1 ? 
            <Details producto={this.state.comprar} Method={this.handleProducts} handleVenta={this.comprarProducto}/> :
               this.state.procesoCompra  === 2 && this.props.Car.length > 0 ? 
            <Buy producto={this.state.comprar} Car={this.props.Car} Total={this.props.Total} setAmounts={this.setAmounts} handleVenta={this.comprarProducto} proceso={this.state.procesoCompra}/> :
              this.state.procesoCompra  === 3 ? 
            <Shipment estado={this.state} producto={this.state.comprar} total={this.state.total} subtotal={this.state.subtotal} iva={this.state.iva} handleVenta={this.comprarProducto} handleInputChange={this.handleInputChange} setTotalSale={this.setTotalSale}/> : 
              this.state.procesoCompra  === 4 ? 
            <Payment estado={this.state.ID_PAYMENT_TYPE} producto={this.state.comprar} handleVenta={this.comprarProducto} handleInputChange={this.handleInputChange} Products={this.state.ID_PRODUCT_AR} Quantity={this.state.QUANTITY_AR} SHIPMENT_TYPE={this.state.ID_SHIPMENT_TYPE} IDENTIFICATION={this.state.PAYMENT_IDENTIFICATION} NAME={this.state.FULLNAME} EMAIL={this.state.EMAIL}/> :
            <Productos productos={this.state.productos} Method={this.handleProducts} MethodComprar={this.comprarProducto}/>}

        
          </div>
          
          
      );
    }

  }

  export default Body;