

import React, { Component } from 'react';
import Body from './Body';

class App extends React.Component{
  constructor(props){
    super(props);
    
    this.state = {
      car: [],
      shop: 0,
      total: 0
    }

    this.handleProducts = this.handleProducts.bind(this);
    this.removeProducts = this.removeProducts.bind(this);
    this.setShop = this.setShop.bind(this);

  }

  setShop(){
    document.getElementById("multiCollapseExample1").setAttribute("class", "collapse multi-collapse");
    this.setState({'shop': this.state.shop + 1});
  }
  handleProducts(item, qty) {
    var temp_car = this.state.car;
    temp_car.push([item, qty]);

    let price = item.discount == 0 ? item.price : item.price_variation;

    this.setState({ 
      car: temp_car,
      total:this.state.total + (price * qty)

   });

  }

  removeProducts(item){
    var temp_car = this.state.car;
    var idx = temp_car.findIndex((e) => e[0].id == item.id);   
    let price = item.discount == 0 ? item.price : item.price_variation; 
    this.setState({car: temp_car, total: this.state.total -  (price * temp_car[idx][1]) });
    temp_car.splice(idx, 1);
  }



    render(){
      return(
        <div className="root">


          <div id="topbar" className="d-none d-lg-flex align-items-center fixed-top">
              <div className="container d-flex">
                  <div className="contact-info mr-auto"></div>
                  <div className="social-links">
                    <a href="#" className="Search" data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1"><i className="icofont-cart-alt icofont-2x"><span className="cart-number">{this.state.car.length}</span></i></a> 
                      <a href="#" className="twitter"><i className="icofont-twitter icofont-2x"></i></a>
                      <a href="#" className="facebook"><i className="icofont-facebook icofont-2x"></i></a>
                      <a href="#" className="Search"><i className="icofont-search icofont-2x"></i></a>                
                  </div>
              </div>
          </div>

          <div className="row position-relative fixed-top div_cart">
            <div className="col-md-8"></div>
            <div className="col-md-4">

              <div className="collapse multi-collapse" id="multiCollapseExample1">
              <div className="card border-primary">
                {/* <img src="..." className="card-img-top" alt="..."> */}
                <div className="card-body">
                  <h5 className="card-title">Sus productos</h5>
                  <p className="card-text"></p>
                </div>
                <ul className="list-group list-group-flush">
                  

                {
                    this.state.car.map((key, index) => {
                      return (
                        //<li className="list-group-item"><img className="images_car" src={key[0].image_path} />{key[0].identification + ' ' + key[0].price} $ <button className="btn btn-danger float-right" onClick={(e) => this.removeProducts(key[0])}>-</button></li>
                        <li className="list-group-item">
                          <div className="row">
                            <div className="col-md-2">
                              <img className="images_car" src={key[0].image_path} />
                            </div>
                            <div className="col-md-3">
                              <span>
                                {key[0].identification }
                              </span>
                            </div>
                            <div className="col-md-2">
                              <span>
                                x{key[1]}
                              </span>
                            </div>
                            <div className="col-md-3">
                              <span>
                                $ {key[0].discount == 0 ? key[0].price : key[0].price_variation}
                              </span>
                            </div>
                            <div className="col-md-2">
                              <button className="btn btn-danger float-right" onClick={(e) => this.removeProducts(key[0])}>-</button>
                            </div>

                          </div>
                          
                          
                        </li>
                      );
                  })
                } 

                </ul>
                <div className="card-body">
                  <span className="float-right">Total:  {this.state.total} $</span>
                <button className={this.state.car.length > 0 ? "btn btn-outline-secondary btn-block" : "none"} onClick={this.setShop}>Comprar</button>
                </div>
              </div>
              </div>


            </div>
          </div>



          <header id="header" className="fixed-top" >
            <div className="container d-flex align-items-center">

                <a href="index.html" className="logo mr-auto"><img src="real/logo.png" alt=""/></a>

                <nav className="nav-menu d-none d-lg-block">
                    <ul>
                        <li className="active"><a href="index.html">INICIO</a></li>
                        <li><a href="#about">SERVICIOS</a></li>
                        <li><a href="/store.html">TIENDA ONLINE</a></li>
                        <li><a href="#portfolio">LITERATURA</a></li>
                        <li><a href="#team">NOSOTROS</a></li>
                        <li><a href="#team">CONTACTO</a></li>

                    </ul>
                </nav>

            </div>
        </header>

        <Body Method={this.handleProducts} Car={this.state.car} Shop={this.state.shop} Total={this.state.total}/>
        </div>



      );
    }

  }

  export default App;
