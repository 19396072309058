import React, { Component } from 'react';

class Productos extends React.Component{
    constructor(props){
      super(props);
  
      this.state = {
        three: [],
        productos: [],
        flag:true
      }
  
      this.handleProducts = props.Method.bind(this);
      this.comprarProducto = props.MethodComprar.bind(this);
      this.filters = this.filters.bind(this);
  
    }
  
    static getDerivedStateFromProps(props, state){
      if(props.productos && state.flag){
        var flag = props.productos.length > 0 ? false : true;
        // HACER EL ARBOL
        var three = [];
        
        // CATEGORIAS
        props.productos.forEach(x => {
          if(three.find((y) => y.id === x.id_category && y.level === 1) === undefined){
            three.push({'level': 1, 'name': x.category, 'id': x.id_category, 'root': null});
          }
        });
        
  
        // SUBCATEGORIAS
        props.productos.forEach(x => {
          if(three.find((y) => y.id === x.id_subcategory && y.level === 2 && y.root === x.id_category) === undefined){
            three.push({'level': 2, 'name': x.subcategory, 'id': x.id_subcategory, 'root':  x.id_category});
          }
        });
  
        //ESTABLECER ESTADO
        return {three: three, productos: props.productos, flag: flag};
      }
    
  
   }
  
   filters(id_category, id_subcategory){
     
    var p = [];
  
    if(this.state.productos.length != this.props.productos.length){
      p = this.props.productos.filter(x => x.id_category === id_category && x.id_subcategory === id_subcategory);
    }else{
      p = this.state.productos.filter(x => x.id_category === id_category && x.id_subcategory === id_subcategory);
    }
  
     this.setState({productos: p});
     console.log(p);
   }
  
  
    
  
    render(){
      return (
  
      <div className="root">
  
            <div className="container">
              <div className="row">
              <div className="col-md-8">
                <h1 id="test">TIENDA ONLINE</h1>
                <div className="s2"></div>
                <h4>Adquiere los mejores productos para tus transportes, contamos con envios a cualquier parte de la republica mexicana.</h4>
              </div>
              <div className="col-md-4">
                  <img src="../assets/img/cotrapsa/store_img.png" alt="" />
              </div>
  
              <div className="col-md-4">
                <h3>Todos los productos ({this.state.productos.length})</h3>
                < hr/>
                <div className="divisors_a">
                  <a href="#" onClick={() => {this.setState({productos: this.props.productos})}}>
                    <span className="badge rounded-pill bg-light span_cat">BORRAR FILTOS <i className="icofont-recycle-alt"></i></span>
                    
                  </a>
  
                </div>
                
                <div className="accordion" id="accordionExample">
  
                  {
                    
                     this.state.three.filter(x => x.level === 1).map((key,index) => {
                      return(
                        
                        <div className="card">
                          <div className="card-header" id={"heading"+key.id}>
                            <h5 className="mb-0">
                            
                              <button className="btn btn-link"  data-toggle="collapse" data-target={"#collapse"+key.id} aria-expanded="false" aria-controls={"collapse"+key.id}>
  
                                  <i className="icofont-plus-circle">{key.name}</i>
                              </button>
                            </h5>
                          </div>
  
                          {
                            this.state.three.filter(y => y.level === 2 && y.root === key.id).map((k, index) => {
                              return(
                                <div id={"collapse"+k.root} className="collapse show" aria-labelledby={"heading"+k.root} data-parent="#accordionExample">
                                <div className="divisors_b">
                                   <a  onClick={(e) => this.filters(k.root, k.id)}>
                                    {/* <span className="primary">{k.name}</span> */}
                                    <span className="badge rounded-pill bg-light span_cat">{k.name}</span>
                                    {/* <span className="badge bg-info text-dark">  </span> */}
                                    <i className="icofont-rounded-right"></i>
                                   </a>
                                </div>
                              </div>
                              );
                            })
                          }
                      
                          
                      </div>
                      );
                      
                    })
                  }
  
  
                                
                </div>
              </div>
              <div className="col-md-8">
              <h3>Productos</h3>
              <div className="row">
  
  
  
              {
                  this.state.productos.map((key, index) => {
                    return (
                    <div className="col-md-4 d-flex align-items-stretch">
                      <div className="card mb-4">
                    <img src={key.image_path} className="c_img" alt="..."/>
                    <div className="card-body">
                      <h5 className="card-title">{key.identification}</h5>
                      <p className="card-text">{key.description}</p>
                      <p className={key.discount === 0 ?"card-text text-muted" : "discount"}><small>$ {key.price}</small></p>
                      {
                        key.discount === 1 ?
                        <p className="card-text"><small >$ {key.price_variation}</small></p>
                        :
                        ""
                      }
                      <a href="#" className="stretched-link" onClick={(e) => this.comprarProducto(key, 1)}></a>

                    </div>
                    </div>
                    </div>
                   
  
                    );
                })
              }
  
              
  
              </div>
              </div>
              </div>
            </div>
      </div>
      );
    }
  
  }

  export default Productos;